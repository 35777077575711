.users-field-container {
    position: relative;
    background-color: var(--color-light);
    border-radius: 10px;
    //padding: 10px;
    margin: 0 !important;
    padding-top: 0px;
    filter: none;

    .field-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 10px 5px 20px;

        >label {
            display: inline-block;
            //padding: 0 5px;
            //background-color: #fff;
            // position: absolute;
            // left: 10px;
            // top: 5px;
            font-weight: 500;
            color: var(--color-text);
            //z-index: 1;
            font-size: 1em;
        }

        >div {
            color: var(--color-text-tint);
            display: flex;
            justify-self: flex-end;
            align-items: center;

            a {

                width: 44px;
                height: 44px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
        }
    }

    .field-body {
        padding: 0 10px;

        .readonly-content {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;

            .no-content {
                margin-top: 0;


                svg {
                    font-size: 40px;
                }

                span {
                    font-weight: 400;
                    font-size: 1.1em;
                }
            }

            >a {
                display: block;
                cursor: pointer;
            }
        }

        .editable-content {
            padding: 10px;
            margin-bottom: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .avatars {

                cursor: pointer;
            }



        }

        .avatars {
            display: flex;
            align-items: center;
        }

        .avatar {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-left: -10px;
            border: 3px solid var(--color-light);
            box-sizing: border-box;
        }

        .additional-count {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: var(--color-medium);
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: -10px;
            color: #fff;
            font-weight: 700;
            line-height: 1;
            border: 3px solid var(--color-light);
            box-sizing: border-box;
            position: relative;
            padding-bottom: 3px;

        }
    }
}