.file-item-container {
    margin: 10px 10px 10px 10px;

    .file-preview {
        width: 120px;
        height: 120px;
        position: relative;
        //padding: 10px;
        box-sizing: border-box;
        overflow: hidden;
        border-radius: 10px;
        //background-color: #fff;
        margin: 10px;

        img {
            max-height: 100%;
            max-width: 100%;
            height: auto;
            width: auto;
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            box-sizing: border-box;
            margin: auto;
        }
    }

    .paper-container {
        padding: 0px 0px 25px 0px;

    }

    .file-content {
        cursor: pointer;
    }

    .file-actions {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        position: absolute;
        bottom: -20px;
        left: 0px;
        width: 100%;
        //padding-top: 10px;
        //background-color: red;

        >* {
            margin: 0 3px;
        }
    }
}