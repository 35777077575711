.notification-item-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    filter: drop-shadow(0px 2px 0 #ddd);
    padding: 10px;
    margin-bottom: 15px;
    cursor: pointer;
}

.notification-item-container.notification-unread {
    background-color: #f8f0e3;
}