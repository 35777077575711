.icon-radio-field-container {
    position: relative;
    background-color: var(--color-light);
    border-radius: 10px;
    //padding-top: 20px;
    padding-bottom: 0px;

    .field-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        width: 100%;
        padding: 5px 5px 5px 15px;
        box-sizing: border-box;

        >label {
            display: inline-block;
            //padding: 0 5px;
            //background-color: #fff;
            // position: absolute;
            // left: 10px;
            // top: 5px;
            font-weight: 500;
            color: var(--color-text);
            z-index: 1;
            font-size: 1em;
        }

        >div {
            // position: absolute;
            // right: 4px;
            // top: 4px;
            color: var(--color-text-tint);
            display: flex;
            justify-self: flex-end;
            align-items: center;

            a {

                width: 44px;
                height: 44px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
        }
    }

    .field-body {
        padding: 30px 10px 10px 10px;
    }

    .editable-items {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        >a {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 10px;
            margin: 10px;
            background-color: #fff;
            border-radius: 20px;
            width: 100px;
            position: relative;
            cursor: pointer;

            >div {

                //background-color: #fff;
                position: absolute;
                top: -12px;
                z-index: 0;
                height: 44px;
                width: 44px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 10px;

                // >svg {
                //     font-size: 35px;
                // }
                >svg:nth-of-type(1) {
                    position: absolute;
                    top: -2px;
                    left: -2px;
                    color: #fff;
                    font-size: 44px;

                }

                >svg:nth-of-type(2) {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    font-size: 40px;
                    color: var(--color-text);

                }
            }

            >span:nth-of-type(1) {
                display: block;
                text-align: center;
                padding-bottom: 15px;
                position: relative;
                top: -3px;
            }

            >span:nth-of-type(2) {
                position: absolute;
                bottom: -10px;
                height: 35px;
                width: 35px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #fff;

                >svg {
                    font-size: 30px;
                }
            }
        }
    }

    .readonly-item {
        >div {
            display: flex;
            justify-content: center;

            >div {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                padding: 10px;
                margin: 10px;
                background-color: #fff;
                border-radius: 20px;
                width: 100px;
                position: relative;
                cursor: pointer;

                >div {

                    background-color: #fff;
                    position: absolute;
                    top: -10px;
                    z-index: 0;
                    height: 40px;
                    width: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 10px;

                    >svg:nth-of-type(1) {
                        position: absolute;
                        top: -2px;
                        left: -2px;
                        color: #fff;
                        font-size: 44px;

                    }

                    >svg:nth-of-type(2) {
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        font-size: 40px;
                        color: var(--color-primary);

                    }
                }

                >span {
                    display: block;
                    text-align: center;
                    padding-bottom: 5px;
                    position: relative;
                    top: 0px;
                    margin-top: 20px;
                    font-weight: 600;
                }
            }
        }

    }
}