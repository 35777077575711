.workflows-expanded-card-container {
    .expanded-card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 10px;

    }

    .expanded-card-body {
        .workflow-list {
            padding-bottom: 60px;
        }
    }

    >.fab {
        position: fixed;
        bottom: 20px;
        right: 20px
    }

}