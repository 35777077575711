.tab-navigator-container {
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 15px;
    left: 0;
    right: 0;
    padding-left: 10px;
    padding-right: 10px;

    >span {
        color: var(--color-text-shade);
        position: absolute;
        bottom: -10px;
        left: 10px;
        font-size: .9em;
        font-weight: 400;
    }

    >div {
        width: 100%;
        max-width: 500px;
        padding: 20px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 60px;
        background-color: #ffffff;
        box-sizing: border-box;
        border-radius: 20px;
        filter: drop-shadow(0px 2px 0 #ddd);

        >a {
            position: relative;
            top: -10px;
            text-decoration: none;
            width: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            font-weight: 500;
            color: var(--color-text);


            >svg:nth-of-type(1) {
                position: absolute;
                top: -2.5px;
                font-size: 40px;
                z-index: -1;
                color: #fff;
            }

            >svg:nth-of-type(2) {
                margin-bottom: 5px;
                font-size: 35px;
            }

            >span {
                position: relative;
                top: -2px;
                display: block;
                text-align: center;
                font-size: .8em;
            }

            .notification-badge-container {
                position: absolute;
                top: -8px;
                left: 50%;
                margin-left: -3px;
            }
        }

        // >a:nth-of-type(4) {
        //     >svg:nth-of-type(1) {
        //         position: absolute;
        //         top: -3px;
        //         font-size: 44px;
        //     }

        //     >svg:nth-of-type(2) {
        //         position: relative;
        //         top: 2px;
        //     }
        // }

        // >a:nth-of-type(3) {

        //     top: -10px;

        //     >svg:nth-of-type(1) {
        //         position: absolute;
        //         top: -5px;
        //         font-size: 60px;
        //         z-index: -1;
        //         color: #ffffff;
        //     }

        //     >svg:nth-of-type(2) {
        //         font-size: 50px;
        //     }

        //     >span {
        //         font-size: 1.1em;
        //     }
        // }

        // >a:nth-of-type(2) {
        //     >svg:nth-of-type(1) {
        //         position: absolute;
        //         top: -3px;
        //         font-size: 46px;
        //     }

        //     >svg:nth-of-type(2) {
        //         position: relative;
        //         top: 3px;
        //         left: 1px;
        //     }
        // }

        >a.middle-tab {
            top: -10px;

            >svg:nth-of-type(1) {
                position: absolute;
                top: -6px;
                font-size: 60px;
                z-index: -1;
                color: #ffffff;
            }

            >svg:nth-of-type(2) {
                font-size: 54px;
                position: relative;
                top: -3px;
            }

            >span {
                // font-size: 1.1em;
                // position: relative;
                // top: -6px;
                font-size: 1em;
                position: relative;
                top: -8px;
                line-height: 1;
            }
        }


        >a.profile-tab {
            bottom: 0px;
            left: 0px;
            width: 65px;
            height: 65px;
            box-sizing: border-box;

            >div {
                position: absolute;
                display: block;
                border-radius: 99px;
                border-style: solid;
                border-width: 5px;
                border-color: #fff;
                box-sizing: border-box;
                width: 65px;
                height: 65px;

                >img {
                    border-radius: 99px;
                    width: 100%;
                    padding: 0;
                    margin: 0;
                }
            }

        }

        >a.profile-tab.active-tab {
            >div {
                border-color: var(--color-primary) !important;
            }

        }


    }

    .active-tab {
        color: var(--color-primary);

        // img {

        //     border-color: var(--color-primary);
        // }

    }
}

@media (max-width: 600px) {
    .tab-navigator-container {
        bottom: 10px;

        >span {
            display: none;
        }

        >div {
            height: 40px;
            border-radius: 10px;

            >a {
                width: 50px;

                >span {
                    display: none;
                }
            }

            // >div {
            //     width: 60px;
            //     height: 60px;
            //     top: -15px;



            //     >a {
            //         bottom: 0px;
            //         left: 0px;
            //         width: 55px;
            //         height: 55px;
            //     }



            // }
            >a.profile-tab {
                width: 60px;
                height: 60px;
                top: -15px;

                >div {
                    width: 60px;
                    height: 60px;

                }

            }
        }
    }
}