.segment-tabs-container {
    display: inline-flex;
    justify-content: center;
    flex-wrap: wrap;
    height: 50px;
    border-radius: 99px;
    background-color: var(--color-light);


    >a {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-radius: 99px;
        padding: 5px 5px;
        //color: var(--color-primary);
        color: var(--color-text-tint);
        font-size: .9em;
        margin: 6px;
        cursor: pointer;
        // position: relative;
        // top: 3px;


        >span {
            // position: relative;
            // top: -2px;
        }

        >svg {
            // position: relative;
            // top: 2px;
        }
    }

    >a.actived {
        background-color: var(--color-text-tint);
        color: #fff;
        font-weight: 500;
    }
}

// .segment-tabs-container {
//     display: flex;
//     justify-content: center;
//     flex-wrap: wrap;
//     border-bottom: 1px solid var(--color-text-tint);
//     padding-bottom: 0px;
//     //padding: 5px;

//     >a {
//         display: inline-flex;
//         justify-content: center;
//         //border-radius: 99px;
//         border-top-left-radius: 10px;
//         border-top-right-radius: 10px;
//         //border: 2px solid var(--color-primary);
//         //padding: 5px 10px;
//         padding: 5px 5px;
//         //color: var(--color-primary);
//         color: var(--color-text-tint);

//         font-size: .9em;
//         margin: 3px;
//         cursor: pointer;
//         position: relative;
//         top: 3px;
//         background-color: var(--color-light);

//         >span {
//             position: relative;
//             top: -2px;
//         }

//         >svg {
//             position: relative;
//             top: 2px;
//         }
//     }

//     >a.actived {
//         background-color: var(--color-text-tint);
//         color: #fff;
//         font-weight: 500;
//     }
// }