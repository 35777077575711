.workflow-entry-detail-modal-container {


    .modal-header {


        >span {
            box-sizing: border-box;
            position: absolute;
            top: 0px;
            left: 0;
            width: 100%;

            //right: 10px;
            color: var(--color-text-tint);
            font-weight: 500;
            font-size: .9em;
            display: flex;
            //justify-content: center;
            padding: 5px;
            justify-content: flex-end;
            align-items: center;
            opacity: .8;

            >svg {
                font-size: 20px;
                margin-right: 3px;
            }
        }
    }

    .step-body-wrapper {
        .step-body-container {
            .form-container {
                padding: 0px 10px 10px 10px;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                padding-bottom: 135px;

                >div {
                    width: 100%;
                }

                .paper {
                    max-width: 800px;
                }


            }

            .comment-fab {
                span.badge {
                    display: block;
                    padding: 3px;
                    font-weight: 900;
                    background-color: var(--color-primary);
                    color: #fff;
                    position: absolute;
                    top: -6px;
                    right: 0px;
                    border-radius: 99px;
                    z-index: 9999;
                    line-height: 1;
                }
            }
        }
    }

    .approval-container {
        >.approval-users {
            display: flex;
            justify-content: center;
            align-items: center;

            >div {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                position: relative;
                margin: 10px;

                >img {
                    width: 40px;
                    height: 40px;
                    border-radius: 99px;
                }

                >span {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                    border-radius: 99px;
                    width: 25px;
                    height: 25px;
                    position: absolute;
                    top: -5px;
                    left: 50%;
                    margin-left: -3px;

                    >svg {
                        font-size: 17px;
                    }
                }

                >div {
                    font-weight: 300;
                }
            }

        }

        >.approval-btns {
            display: flex;
            justify-content: center;
            align-items: center;

            >button {
                margin: 5px;

                >svg {
                    margin-right: 3px;
                }
            }
        }
    }
}