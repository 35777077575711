.color-cell-container {
    display: flex;
    justify-content: center;
    align-items: center;

    >span {
        width: 40px;
        height: 40px;
        border-radius: 99px;
        display: block;
    }
}