.stepper-cell-container {
    display: flex;
    justify-content: flex-end;
    align-items: start;
    flex-wrap: wrap;

    >.step-group:first-child {
        &::after {
            content: none;
        }

        >.step:first-child {
            &::before {
                left: 50%;
            }
        }
    }

    >.step-group:last-child {
        &::before {
            content: none;
        }

        >.step:last-child {
            &::before {
                right: 50%;
            }
        }
    }

    >.step-group {
        //background-color: red;
        display: flex;
        justify-content: flex-end;
        align-items: start;
        position: relative;
        margin: 5px 0;

        &::before {
            border-top: 2.5px dashed var(--color-primary);
            content: '';
            display: block;
            height: 0;
            position: absolute;
            right: 0;
            top: 55.5px;
            right: -17px;
            //transform: translate(0, -6px);
            width: 15px;
            //z-index: 99;
        }

        &::after {
            border-top: 2.5px dashed var(--color-primary);
            content: '';
            display: block;
            height: 0;
            position: absolute;
            right: 0;
            top: 55.5px;
            left: -17px;
            //transform: translate(0, -6px);
            width: 15px;
            //z-index: 99;
        }

        >.step {
            //background-color: blue;
            //margin: 4px;
            height: 95px;
            position: relative;
            box-sizing: border-box;
            width: 90px;

            &::before {
                content: "";
                height: 2.5px;
                position: absolute;
                left: 0;
                right: 0;
                //top: 36px;
                bottom: 37px;
                background-color: var(--color-primary);
            }

            >.step-name {
                //background-color: violet;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;

                >h3 {
                    text-align: center;
                    margin: 0;
                    padding: 0;
                    font-size: 13px;
                    font-weight: 400;

                    /* Estilo para limitar el alto a 3 líneas y agregar puntos suspensivos */
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }

            }


            >span {
                align-items: center;
                //background-color: var(--color-primary);
                background-color: #fff;
                border: 3px solid var(--color-primary);
                border-radius: 100%;
                display: flex;
                height: 25px;
                justify-content: center;
                left: 50%;
                position: absolute;
                // top: 25px;
                // 
                bottom: 25px;
                transform: translate(-50%, 0);
                width: 25px;
                box-sizing: border-box;
                z-index: 100;
                //color: #fff;
                color: var(--color-primary);
                font-weight: 900;
                font-size: 16px;
                line-height: 14px;

                svg {
                    font-size: 15px;
                }
            }

            >.status-text {
                position: absolute;
                height: 25px;
                bottom: -3px;
                text-align: center;
                //line-height: 25px;
                line-height: 1.1;
                //background-color: red;
                font-size: .8em;
                font-weight: 300;
                display: block;
                width: 100%;
            }

            >div {}

        }
    }
}

@media(max-width: 600px) {
    .workflow-entry-stepper-cell-container {
        justify-content: center;
    }
}