.mini-grouped-column-chart-container {
    .main-content {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        flex: 1;

        >div {
            //width: 100%;
            flex: 1;
            //margin: .5%;
            padding: 0 1.5%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            box-sizing: border-box;

            >a {

                flex: 1;
                width: 100%;
                margin: 0 1%;
                animation: animHeight 1s ease-in-out;
                border-radius: 10px 10px 0 0;
                cursor: pointer;
            }
        }
    }

    .columns-wrapper {
        background-color: white;
    }

    // .columns-wrapper:hover {

    //     background-color: var(--color-light);
    // }

    @keyframes animHeight {
        from {
            height: 0px;
        }
    }
}