.selected-configuration-items-container {
    height: 100%;
    width: 100%;
    max-width: 600px;

    .paper-container {
        height: 80%;

        >.paper-body {
            height: 100%;
        }
    }

    .item-description {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: .9em;
        margin-left: 3px;

        >span {
            font-weight: 300;
        }
    }

    .selected-configuration-list {
        // .dynamic-list-item-container {
        //     filter: none;
        //     background-color: var(--color-light);
        // }
    }
}