.tasks-calendar-modal-container {
    // position: absolute;
    // left: 0;
    // right: 0;
    // top: 0;
    // bottom: 0;

    .header-searchbar-wrapper {

        margin-right: 10px;
    }

    .fab-btn {
        position: fixed;
        bottom: 20px;
        right: 20px;
        color: #fff;
    }

    .item-main-content {
        >* {
            display: block;
        }

        >b {
            font-size: .8em;
        }

        >span {
            font-weight: 300;
        }

        .due-date-wrapper {
            display: flex;
            align-items: center;

            >svg {
                margin-right: 5px;
            }
        }

    }
}