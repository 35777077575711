.counter-container {
    width: 100%;
    justify-content: space-between;

    >button {
        font-weight: 900;
        color: var(--color-text);
        font-size: 20px;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    >input {
        box-sizing: border-box;
        margin: 0 5px;
    }
}