.pdf-viewer-modal-container {
    .modal-body {
        display: flex;
        justify-content: center;
        padding: 0px;
        height: 100%;
        box-sizing: border-box;

        .pdf-wrapper {
            width: 100%;
        }
    }

}

@media screen and (max-width: 600px) {
    .pdf-viewer-modal-container {}

}