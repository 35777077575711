.tags-field-container {
    position: relative;
    background-color: var(--color-light);
    border-radius: 10px;
    //padding-top: 20px;
    padding-bottom: 0px;

    // >label {
    //     display: inline-block;
    //     padding: 0 5px;
    //     //background-color: #fff;
    //     position: absolute;
    //     left: 10px;
    //     top: 5px;
    //     font-weight: 500;
    //     color: var(--color-text);
    //     z-index: 1;
    //     font-size: 1em;
    // }
    .field-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        width: 100%;
        padding: 5px 5px 5px 15px;
        box-sizing: border-box;

        >label {
            display: inline-block;
            //padding: 0 5px;
            //background-color: #fff;
            // position: absolute;
            // left: 10px;
            // top: 5px;
            font-weight: 500;
            color: var(--color-text);
            z-index: 1;
            font-size: 1em;
        }

        >div {
            // position: absolute;
            // right: 4px;
            // top: 4px;
            color: var(--color-text-tint);
            display: flex;
            justify-self: flex-end;
            align-items: center;

            a {

                width: 44px;
                height: 44px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
        }
    }

    .field-body {
        padding: 35px 0px 3px 0px;

    }

    .readonly-content {
        margin: 10px;

        >div {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            padding: 0px 5px;

            >span {
                margin: 5px;
                border-style: solid;
                padding: 5px 8px;
                display: flex;
                justify-content: center;
                align-items: center;

                >svg {
                    margin-right: 5px;
                    font-size: 17px;
                }

                >span {
                    line-height: 1;
                    font-weight: 500;
                    position: relative;
                    top: -1px;
                    font-size: .9em;
                }
            }
        }

    }

    .editable-content {

        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        padding: 10px;
        // margin-top: 10px;

        >button {
            margin: 5px;
            border-color: var(--color-medium);
            color: var(--color-medium);
        }

        >button:hover {
            border-color: var(--color-medium);
        }

        >div {
            display: flex;
            justify-content: center;
            align-items: center;

            width: 100%;
        }
    }

    .spinner-wrapper {
        margin-top: 0px;
        display: flex;
        justify-content: center;
        padding: 15px;
    }
}