.page-container {
    padding-bottom: 100px;

    >.page-header {
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        >h1 {
            font-weight: 900;
            margin: 0px;
            padding: 0px;
            display: block;
            font-size: 26px;
        }
    }

    >.page-body {
        //padding: 10px;
        box-sizing: border-box;
    }

    >.fab {
        position: fixed;
        bottom: 20px;
        right: 20px
    }
}

.logo-wrapper {
    padding-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    >img {
        width: 100%;
        max-width: 200px;

    }
}

.spinner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 40px;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;

    >.modal-title {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        flex: 1;

        >h2 {
            font-weight: 900;
            font-size: 1.4em;
            margin: 0px;
            padding: 0px;
        }

        >span {
            font-weight: 300;
            font-size: 1em;
        }
    }

    >.modal-start-btns {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    >.modal-end-btns {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        >*:not(:last-child) {
            margin-right: 10px;
        }

    }
}

.no-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    margin-top: 40px;


    >svg {
        font-size: 80px;
        color: var(--color-text-tint);

    }

    >span {
        font-size: .9em;
        color: var(--color-text-tint);
        display: block;
        width: 180px;
        text-align: center;
    }
}

reach-portal {
    >div {
        z-index: 9999;
    }
}

input {
    width: 100%;
    height: 30px;
    line-height: 25px;
    border: none;
    font-size: .9em;
    padding: 5px;
    color: var(--color-text);
    font-weight: 500;
    outline: none;
    background-color: transparent;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px #fff inset !important;
}

.field-description {
    font-weight: 300;
    font-style: italic;
    display: block;
    padding: 3px;

}

.expanded-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
    position: relative;
    z-index: 9;

    >h2 {
        margin: 0;
        font-weight: 900;
        padding: 5px;
    }

    >div {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: nowrap;

        >button {
            margin-right: 10px;
            margin-left: 5px;
        }

    }

}

.drawer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px 5px;
    background-color: var(--color-light);
    z-index: 1;

    >div {
        >h3 {
            padding: 0;
            margin: 0;
        }

        >span {}
    }
}

.drawer-body {
    background-color: var(--color-light);
}

.drawer-footer {
    background-color: var(--color-light);
}

.MuiAutocomplete-popper {
    z-index: 99999999 !important;
}

.input-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    background-color: #fff;
    border-radius: 10px;

    >span {
        padding: 5px !important;
        font-weight: 700;
        position: relative;
        top: -2px;

    }

    >input {


        width: 100%;
        height: 30px;
        line-height: 25px;
        border: none;
        font-size: .9em;
        padding: 5px;
        color: var(--color-text);
        font-weight: 500;
        outline: none;
        box-sizing: border-box;
        text-align: right;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        box-shadow: 0 0 0 30px #fff inset !important;
    }
}

.dialog-form-title {
    font-weight: 900 !important;
}

.dialog-form-content {
    padding-top: 20px !important;
}

@media screen and (max-width: 600px) {
    .page-container {
        >.page-header {
            >h1 {
                font-size: 22px;
            }
        }
    }
}

//max-width
@for $i from 1 through 20 {
    .maxw-#{50 * $i} {
        width: 100%;
        max-width: 50px * $i !important;
    }
}

@for $i from 1 through 50 {
    .minw-#{5 * $i} {
        min-width: 5px * $i !important;
    }
}

//fixed width
@for $i from 1 through 100 {
    .w-#{5 * $i} {
        width: 5px * $i !important;
    }
}

//Otros anchos
.w-full {
    width: 100% !important;
}

@media(max-width: 600px) {
    .w-full-xs {
        width: 100% !important;
    }
}

@for $i from 1 through 12 {
    .w-1_#{$i} {
        width: 100% / 12 * $i !important;
        //width: math.div(100%, 12) !important;
    }
}

//min-height
@for $i from 0 through 20 {
    .minh-#{10 * $i} {
        min-height: 10px * $i !important;
    }
}

//heights
@for $i from 1 through 20 {
    .h-#{10 * $i} {
        height: 10px * $i !important;
    }
}

//font-size
@for $i from 10 through 50 {
    .fs-#{$i} {
        font-size: 0px + $i !important;
    }
}

//font-weight
@for $i from 1 through 9 {
    .fw-#{100 * $i} {
        font-weight: 100 * $i !important;
    }
}

//Font
.fw-b {
    font-weight: bold !important;
}

.fw-n {
    font-weight: normal !important;
}

//text align
.ta-c {
    text-align: center !important;
}

.ta-l {
    text-align: left !important;
}

.ta-r {
    text-align: right !important;
}

//margin y padding
@for $i from 0 through 50 {
    .m-#{5 * $i} {
        margin: 5px * $i !important;
    }

    .ml-#{5 * $i} {
        margin-left: 5px * $i !important;
    }

    .mr-#{5 * $i} {
        margin-right: 5px * $i !important;
    }

    .mt-#{5 * $i} {
        margin-top: 5px * $i !important;
    }

    .mb-#{5 * $i} {
        margin-bottom: 5px * $i !important;
    }

    .ml--#{5 * $i} {
        margin-left: -5px * $i !important;
    }

    .mr--#{5 * $i} {
        margin-right: -5px * $i !important;
    }

    .mt--#{5 * $i} {
        margin-top: -5px * $i !important;
    }

    .mb--#{5 * $i} {
        margin-bottom: -5px * $i !important;
    }

    .p-#{5 * $i} {
        padding: 5px * $i !important;
    }

    .pl-#{5 * $i} {
        padding-left: 5px * $i !important;
    }

    .pr-#{5 * $i} {
        padding-right: 5px * $i !important;
    }

    .pt-#{5 * $i} {
        padding-top: 5px * $i !important;
    }

    .pb-#{5 * $i} {
        padding-bottom: 5px * $i !important;
    }
}

.d-ib {
    display: inline-block !important;
}

.d-b {
    display: block !important;
}

.d-n {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}