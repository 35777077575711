.checklist-field-container {
    position: relative;
    background-color: var(--color-light);
    border-radius: 10px;
    margin: 0 !important;
    padding-top: 0px;
    padding-right: 5px;
    padding-left: 5px;
    filter: none;

    .field-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 10px 0px 10px;

        >label {
            display: inline-block;
            font-weight: 500;
            color: var(--color-text);
            font-size: 1em;
        }

        >div {
            color: var(--color-text-tint);
            display: flex;
            justify-self: flex-end;
            align-items: center;

            a {

                width: 44px;
                height: 44px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
        }
    }

    .field-body {
        padding-bottom: 5px;


    }


}