.pastel-color-field-container {

    width: 100%;
    border-color: var(--color-medium);
    border-width: 2px;
    border-radius: 10px;
    border-style: solid;
    box-sizing: border-box;
    background-color: #fff;
    //background: var(--color-light);
    position: relative;

    >label {
        position: absolute;
        top: -13px;
        left: 10px;
        display: inline-block;
        padding: 0 5px;
        background: #fff;
        font-weight: 600;
        color: var(--color-text);

    }

    >div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 7px;


        >div:nth-of-type(1) {
            width: 100%;

            span {
                height: 40px;
                line-height: 34px;
                color: var(--color-text);
                display: block;
                font-weight: 400;
                padding-top: 4px;
                box-sizing: border-box;
            }

            >.editable-content {

                padding: 7px 0 7px 0;




                >a {
                    width: 100%;

                    height: 28px;
                    display: block;

                    cursor: pointer;
                    border-radius: 10px;
                    position: relative;
                    z-index: 1;
                }


            }

            >.readonly-content {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding-top: 3px;

                >span {
                    display: block;
                    height: 30px;
                    width: 100%;
                    border-radius: 5px;
                    z-index: 1;
                }
            }
        }

        >div:nth-of-type(2) {
            display: flex;
            justify-content: center;
            align-items: center;

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40px;
                height: 40px;

                cursor: pointer;
            }
        }

        svg {
            color: var(--color-text-tint);
        }
    }

}

.pastel-colors-popover-items {
    background-color: #fff;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-width: 150px;

    >a {
        display: block;
        width: 40px;
        height: 40px;
        cursor: pointer;
        border-radius: 99px;
        margin: 5px;
    }
}