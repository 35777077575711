.interactive-action-item-container {
    >a {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 110px;
        width: 110px;
        background-color: #fff;
        border-radius: 20px;
        padding: 10px;

        position: relative;
        box-sizing: border-box;
        filter: drop-shadow(0px 2px 0 #ccc);
        cursor: pointer;

        >svg {
            margin-bottom: 5px;
            font-size: 35px;
        }

        >span {
            font-weight: 500;
            display: block;
            text-align: center;
            font-size: .9em;
        }
    }

    >a.disabled {
        opacity: .8;
        filter: none;
        cursor: auto;
    }

}