.board-page-container {
    padding-bottom: 100px;

    .spinner-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 60px;

    }

    .board-cards {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        >div {
            width: 100%;
            max-width: 165px;
            height: 215px;
            margin: 5px;
        }

        >a {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            color: var(--color-text-tint);
            border-color: var(--color-medium);
            border-width: 1.5px;
            border-style: dashed;
            cursor: pointer;
            padding: 25px;
            border-radius: 20px;
            width: 165px;
            height: 180px;
            margin: 5px;
            box-sizing: border-box;
            position: relative;
            top: -10px;

            >span {
                display: block;
                margin-bottom: 15px;
                font-size: 20px;
                text-align: center;
            }

            >svg {
                font-size: 50px;
            }
        }
    }

    .empty-board-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 100px;

        >a {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            color: var(--color-text-tint);
            border-color: var(--color-medium);
            border-width: 1.5px;
            border-style: dashed;
            cursor: pointer;
            padding: 25px;
            border-radius: 20px;

            >span {
                display: block;
                margin-bottom: 15px;
                font-size: 20px;
            }

            >svg {
                font-size: 70px;
            }
        }
    }

    .loading-button-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}