.my-ring-binders-page-container {
    .page-body {
        .horizontal-binders {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;

            >* {
                margin: 10px;
            }
        }
    }
}