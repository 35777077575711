.payment-field-container {



    .field-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 5px 5px 5px 15px;
        box-sizing: border-box;

        >label {
            display: inline-block;
            font-weight: 700;
            color: var(--color-text);
            z-index: 1;
            font-size: 1.1em;
            min-height: 50px;
            display: flex;
            align-items: center;

        }
    }

    .field-body {
        padding-bottom: 5px;
    }
}