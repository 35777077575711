.coordinates-field-container {
    position: relative;
    background-color: var(--color-light);
    border-radius: 10px;
    padding: 10px;

    >label {
        display: inline-block;
        padding: 0 5px;


        font-weight: 500;
        color: var(--color-text);
        z-index: 1;
        font-size: 1em;
    }

    >div {
        margin-top: 10px;

    }

    .readonly-content {}

    .editable-content {
        >div:nth-of-type(1) {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;

            >div {
                margin: 5px;

                >div.input-wrapper {
                    position: relative;

                    >label {
                        position: absolute;
                        top: -13px;
                        left: 10px;
                        display: inline-block;
                        padding: 0 5px;
                        background: var(--color-light);
                        font-weight: 600;
                        color: var(--color-text);

                    }

                    >div {
                        padding: 0 10px;
                        border-color: var(--color-medium);
                        border-width: 2px;
                        border-radius: 10px;
                        border-style: solid;
                        box-sizing: border-box;

                        >input {
                            height: 25px !important;
                            line-height: 20px !important;
                        }
                    }
                }

                // .input-field-container {
                //     >label {
                //         background-color: var(--color-light);
                //     }

                //     >div {
                //         background-color: var(--color-light);
                //         border-radius: 10px;

                //         input {
                //             background-color: var(--color-light);
                //         }
                //     }
                // }
            }

        }

        >div:nth-of-type(2) {}
    }
}