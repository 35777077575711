.website-builder-card-container {
    >.card-title {
        height: 22px;
    }

    >div {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
    }
}