.color-field-container {
    position: relative;
    background-color: var(--color-light);
    border-radius: 10px;
    padding-top: 20px;

    >label {
        display: inline-block;
        padding: 0 5px;
        position: absolute;
        left: 10px;
        top: 5px;
        font-weight: 500;
        color: var(--color-text);
        z-index: 1;
        font-size: 1em;
    }

    >.content {
        position: relative;

        >.editable-content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            padding: 10px;
        }

        >.readonly-content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;

            padding: 10px;

            .no-value {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                background-color: #fff;
                padding: 10px;
                border-radius: 10px;
                width: 100px;
                height: 100px;

                >svg {
                    color: var(--color-medium);
                }

                >span {
                    display: block;
                    text-align: center;
                    font-weight: 500;
                    color: var(--color-text);
                    font-size: .9em;
                    margin-top: 10px;
                }

            }

            .readonly-color {
                display: block;
                border-radius: 10px;
                width: 80px;
                height: 80px;
            }
        }
    }

    >.btns {
        position: absolute;
        right: 10px;
        top: 5px;

        a {
            cursor: pointer;
            color: var(--color-text-tint);
        }


    }

    .color-picker {
        width: 100%;
        display: flex;
        justify-content: center;

        >a {
            border: 1px solid #ccc;
            display: block;
            border-radius: 10px;
            width: 60px;
            height: 60px;
            cursor: pointer;
        }
    }

    .selected-color {
        position: relative;
        border: 1px solid #ccc;
        display: block;
        border-radius: 10px;
        width: 60px;
        height: 60px;
        cursor: pointer;

        >span {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20px;
            height: 20px;
            border-radius: 99px;
            border-width: 5px;
            border-style: solid;
            border-color: var(--color-light);
            position: absolute;
            background-color: #fff;
            top: -10px;
            right: -10px;

            svg {
                font-size: 15px;
            }
        }
    }
}

.popover-colors-table-wrapper {
    background-color: #fff;
    box-shadow: 0 1px 2px #999;

    >table {
        width: 100%;

        td {
            >a {
                display: block;
                width: 100%;
                height: 30px;
                cursor: pointer;

            }

        }
    }

    >div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px;

        >span {
            display: block;
            width: 50px;
            height: 30px;
            border: 1px solid #ccc;
        }

        >div {
            margin-left: 10px;
            border-color: var(--color-medium);
            border-width: 2px;
            border-radius: 99px;
            border-style: solid;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            align-items: center;

            >strong {
                font-size: 1.3em;
                padding-left: 5px;
            }

            >input {
                border-radius: 99px;
                width: 100%;
                height: 25px;
                line-height: 25px;
                border: none;
                font-size: .9em;
                padding: 5px;
                color: var(--color-text);
                font-weight: 500;
                outline: none;

            }

            >button {
                color: var(--color-text);

            }
        }
    }
}