.readonly-checklist-field-container {
    >div {
        display: flex;
        justify-content: space-between;
        align-items: center;

        >span {
            padding-left: 20px;
        }
    }
}