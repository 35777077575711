.onboarding-page-container {
    padding-bottom: 40px;

    .steps-wrapper {
        width: 100%;
        max-width: 700px;
        margin: 10px auto;
        padding: 10px;
        text-align: left;
        box-sizing: border-box;

        .step {

            display: flex;
            justify-content: space-between;

            >.step-status {
                width: 30px;
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    width: 26px;
                    height: 26px;
                    left: 50%;
                    margin-left: -13px;
                    margin-top: -13px;
                    background-color: var(--color-light);
                    z-index: 1;
                    border-radius: 99px;
                }

                &::after {
                    content: '';
                    position: absolute;
                    top: 0px;
                    width: 2px;
                    left: 50%;
                    margin-left: -1px;
                    bottom: 0px;
                    background-color: var(--color-primary);

                }

                >svg {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin-left: -15px;
                    margin-top: -15px;
                    font-size: 30px;
                    z-index: 2;
                    color: var(--color-primary);
                }
            }

            .step-content {
                background-color: #fff;
                flex: 1;
                margin-left: 20px;
                margin-bottom: 10px;
                margin-top: 10px;
                padding: 5px;
                border-radius: 10px;
                position: relative;
                filter: drop-shadow(0 1px 0 #ccc);
                box-sizing: border-box;

                &::before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    margin-top: -10px;
                    width: 0;
                    height: 0;
                    border-top: 10px solid transparent;
                    border-bottom: 10px solid transparent;
                    border-right: 10px solid #fff;
                    left: -8px;
                }

                >h2 {
                    padding: 5px;
                    margin: 0;
                    font-size: 1.1em;
                    font-weight: 700;
                }

                >div {
                    font-size: .9em;
                    font-weight: 300;
                    padding: 5px;
                    box-sizing: border-box;
                }


            }
        }

        >.step:first-child {
            >.step-status {
                &::after {
                    top: 50%;
                }
            }
        }

        >.step:last-child {
            >.step-status {
                &::after {
                    bottom: 50%;
                }
            }
        }
    }

    .btns-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        height: 60px;

        position: fixed;
        bottom: 0px;
        left: 0;
        right: 0;
        z-index: 99;
        background-color: var(--color-light);

        >button {
            margin: 0 5px;
        }

    }
}