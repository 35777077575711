.configuration-item-editor-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 85%;
    max-width: 500px;
    background-color: #fff;
    transition: transform .4s ease-in-out;
    z-index: 1;

    >a {
        display: flex;
        width: 40px;
        height: 40px;
        color: #fff;
        justify-content: center;
        align-items: center;
        border-radius: 10px 0px 0px 10px;
        cursor: pointer;
        position: absolute;
        left: -40px
    }

    >a:nth-of-type(1) {
        top: 40px;
        background-color: var(--color-dark);
    }

    >a:nth-of-type(2) {
        top: 100px;
        background-color: var(--color-primary);

    }

    >a.disabled {
        opacity: .4;
    }

    >div {
        overflow-y: auto;
        width: 100%;
        height: 100%;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 15px;
        padding-bottom: 15px;
        box-sizing: border-box;

        >div {
            margin: 20px 0;
        }

    }
}

.configuration-item-editor-container.visible {
    transform: translateX(0);
}

.configuration-item-editor-container.hidden {
    transform: translateX(100%);
}

@media screen and (max-width: 600px) {
    .configuration-item-editor-container {
        >a:nth-of-type(1) {
            top: 140px;
        }

        >a:nth-of-type(2) {
            top: 200px;
        }
    }
}