.editable-billing-field {
    .select-party-btn-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;

        >button {
            border-radius: 99px;
            border-width: 2px;
        }
    }

    .selected-party {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;

        >div {
            >strong {
                display: block;
                margin-bottom: 5px;

            }

            >span {
                font-weight: 300;
                font-size: .9em;
            }
        }

    }

    .add-item-wrapper {
        width: 100%;
        max-width: 800px;
        margin: 0 auto;
        //background-color: red;

        >div {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;

            .autocomplete-container {
                width: 100%;


            }

            >button {
                margin-left: 10px;

                svg {
                    color: var(--color-primary)
                }
            }
        }


    }

    .items-wrapper {
        margin-bottom: 20px;

        .no-content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 20px;
            margin-top: 40px;


            >svg {
                font-size: 80px;
                color: var(--color-text-tint);

            }

            >span {
                font-size: .9em;
                color: var(--color-text-tint);
                display: block;
                width: 180px;
                text-align: center;
            }
        }

        table {
            border-collapse: separate;
            border-spacing: 0px 10px;
            width: 100%;

            th {
                padding: 5px;
            }
        }
    }

    .totals-wrapper {

        display: flex;
        justify-content: flex-end;

        >div {
            background-color: var(--color-light);
            padding: 0px 0px 5px 5px;
            border-radius: 10px;

            >table {
                border-collapse: separate;
                border-spacing: 5px 10px;

                tr {
                    td {
                        //border-bottom: 1px solid #ccc;
                    }

                }

                .main-total-wrapper {
                    td {
                        font-weight: 800;
                        font-size: 1.2em;
                    }
                }
            }

            >div {
                margin-top: 15px;
            }

        }
    }

    .payment-wrapper {

        display: flex;
        justify-content: flex-end;
        margin-top: 20px;

        >div {
            background-color: var(--color-light);
            padding: 10px;
            border-radius: 10px;

            >h3 {
                padding: 5px;
                margin: 0px;
                text-align: right;
                font-weight: 800;
                font-size: 1.2em;
            }

            >div:nth-of-type(1) {
                >div {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: nowrap;
                    margin: 5px 0px;

                    >span {
                        min-width: 100px;
                    }

                    >div {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        flex-wrap: nowrap;



                    }
                }
            }

            >div:nth-of-type(2) {
                margin-top: 15px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: nowrap;


                >span {
                    font-weight: 800;
                    font-size: 1.2em;
                }

                >strong {
                    font-weight: 800;
                    font-size: 1.4em;
                }
            }
        }

    }

    .all-items-delivered-wrapper {
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;

        >div {
            width: 100%;
            max-width: 400px;

            .field-body {
                padding-top: 5px;
            }
        }
    }
}