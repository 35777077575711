.mini-stacked-column-chart-container {
    .main-content {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        flex: 1;



        >div {
            flex: 1;
            padding: 0 1.5%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex-direction: column;
            box-sizing: border-box;

            >a,
            span {
                //flex: 1;
                width: 100%;
                //margin: 0 4%;
                animation: animHeight .7s ease-in-out;
                //border-radius: 10px 10px 0 0;
                cursor: pointer;
            }

            >a:first-child,
            span:first-child {
                border-radius: 10px 10px 0 0;
            }
        }
    }

    .column-wrapper {

        background-color: white;
    }

    .column-wrapper:hover {

        //background-color: var(--color-light);
    }

    @keyframes animHeight {
        from {
            height: 0px;
        }
    }
}