.stacked-column-chart-container {

    .chart {
        margin: 20px 0;

        >div:nth-of-type(1) {
            display: flex;
            justify-content: space-between;

            .y-axis {
                width: 70px;


                display: flex;
                justify-content: space-between;
                align-items: space-between;
                flex-direction: column;


                >span {
                    transform: rotate(-20deg);
                    font-size: .9em;

                    transform-origin: 0%;
                    padding-left: 3px;
                    font-weight: 300;
                    color: var(--color-text);
                    position: relative;

                    //font-size: ;
                }
            }

            .main-content {
                display: flex;
                justify-content: flex-start;
                align-items: flex-end;
                flex: 1;
                box-sizing: border-box;
                padding-right: 40px;

                >div {

                    flex: 1;
                    padding: 0 1.5%;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    flex-direction: column;
                    box-sizing: border-box;

                    >span {
                        display: block;
                        width: 100%;
                        animation: animHeight .7s ease-in-out;
                    }

                    >span:first-child {
                        border-radius: 10px 10px 0 0;
                    }
                }
            }
        }

        >.x-axis {
            //height: 80px;
            width: 100%;
            //background-color: red;
            padding-top: 5px;
            padding-left: 70px;
            padding-right: 40px;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;

            >span {
                transform: rotate(-20deg);
                transform-origin: 100% 0%;
                font-weight: 600;
                color: var(--color-text);
                font-size: .9em;
                // position: relative;
                // left: 5px;
            }
        }
    }

    .columns-wrapper {

        background-color: white;
    }

    .columns-wrapper:hover {

        //background-color: var(--color-light);
    }



    .legend {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        >a.legend-item {
            padding: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            >span.legend-color {
                width: 20px;
                height: 20px;
                margin-right: 10px;

            }

            >span.legend-label {
                font-weight: 400;
                font-size: .9em;
                color: var(--color-text);
                flex: 1;
            }
        }
    }

    @keyframes animHeight {
        from {
            height: 0px;
        }
    }
}

.sub-column-tooltip {
    color: var(--color-text-shade);
    overflow-y: auto;
    max-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    >strong {
        font-weight: 800;
        color: var(--color-text);
        margin-bottom: 3px;
    }

    >span {
        font-weight: 300;
        font-size: 1em;
        margin-bottom: 3px;
    }

    >div {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        >strong {
            display: inline-block;
            font-weight: 900;
            font-size: 1.2em;
            margin-right: 3px;
        }

        >span {
            color: var(--color-text-tint);
        }

    }
}