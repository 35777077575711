.dynamic-list-field-container {
    .field-header {}

    >.field-body {
        //padding: 10px;
        padding: 35px 10px 10px 10px;

        .editable-content {
            .add-btn-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                //margin-top: 10px;
                margin-bottom: 10px;
            }

            .list-wrapper {
                >div {
                    background-color: #fff;
                    border-radius: 10px;
                    margin-bottom: 25px;
                    padding: 15px 10px 25px 10px;
                    position: relative;

                    >span {
                        display: block;
                        margin-bottom: 10px;
                    }

                    >.footer-btns {
                        display: flex;
                        justify-content: center;
                        position: absolute;
                        width: 100%;
                        bottom: -15px;

                        >button {
                            background-color: #fff;
                            margin: 0 4px;
                        }



                    }
                }
            }

            .selected-item {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                >span {
                    margin-right: 5px;
                    font-weight: 500;
                }

            }

            .number-input {
                border-radius: 10px;
                border-width: 2px;
                border-style: solid;
                border-color: var(--color-medium);
                height: 44px;
                width: 100%;
                box-sizing: border-box;
            }

            .autocomplete-wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex: 1;

                >div {
                    flex: 1;

                }

                >button {
                    margin-left: 5px;
                }
            }

            .text-input,
            .long-text-input {
                border: 2px solid #bbcad4;
                border-radius: 10px;
            }

            .list-wrapper,
            .object-wrapper {
                .add-item-btn-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    button {
                        font-size: .8em;

                        svg {
                            font-size: 1.1em;
                        }
                    }

                }

                .items-wrapper {
                    >div {
                        margin-bottom: 10px;
                        border: 2px solid #bbcad4;
                        border-radius: 10px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding-right: 5px;

                        svg {
                            font-size: 1.1em;
                        }
                    }
                }
            }

            .object-wrapper {


                .items-wrapper {
                    >div {
                        >span {
                            position: relative;
                            top: -2px;
                        }
                    }

                }
            }

            .images-field-container {
                padding: 0px;
                background-color: #fff;
            }

            .img-preview-wrapper {
                background-color: var(--color-light);
            }

            .radio-field-container {
                background-color: #fff;
            }
        }

        .readonly-content {
            >span {
                display: block;
                padding: 0px 0px 5px 0px;
                text-align: center;
            }
        }
    }

    .radio-field-container {
        padding-top: 0px;

        >div {
            margin-top: 0px;
            margin-bottom: 0px;
        }
    }
}


@media screen and (max-width: 600px) {
    .dynamic-list-field-container {
        .list-wrapper {
            padding: 0px !important;
        }
    }

}