.notification-badge-container {
    background-color: var(--color-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 99px;
    width: 24px;
    height: 24px;
    color: #fff;
    font-size: .8em;
    font-weight: 600;
    border: 4px solid #fff;

    >span {
        position: relative;
        top: -1px;
    }
}