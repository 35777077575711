.simple-payment-field-container {
    position: relative;
    background-color: var(--color-light);
    border-radius: 10px;
    //padding-top: 20px;
    padding-bottom: 0px;

    .field-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        width: 100%;
        padding: 5px 5px 5px 15px;
        box-sizing: border-box;

        >label {
            display: inline-block;
            //padding: 0 5px;
            //background-color: #fff;
            // position: absolute;
            // left: 10px;
            // top: 5px;
            font-weight: 500;
            color: var(--color-text);
            z-index: 1;
            font-size: 1em;
        }

        >div {
            // position: absolute;
            // right: 4px;
            // top: 4px;
            color: var(--color-text-tint);
            display: flex;
            justify-self: flex-end;
            align-items: center;

            a {

                width: 44px;
                height: 44px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
        }
    }

    .field-body {
        padding: 30px 10px 10px 10px;

        .input-group {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: nowrap;
            background-color: #fff;
            border-radius: 10px;

            >span {
                padding: 5px !important;
                font-weight: 700;
                position: relative;
                top: -2px;

            }

            >input {


                width: 100%;
                height: 30px;
                line-height: 25px;
                border: none;
                font-size: .9em;
                padding: 5px;
                color: var(--color-text);
                font-weight: 500;
                outline: none;
                box-sizing: border-box;
                text-align: right;
            }

            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            input:-webkit-autofill:active {
                box-shadow: 0 0 0 30px #fff inset !important;
            }
        }
    }
}