.editable-billing-item-container {
    td {
        background-color: var(--color-light);
        padding: 3px;

        >span {
            padding: 5px;
            border-right: 1px solid #fff;
            display: block;
        }

        &:first-child {
            border-radius: 5px 0px 0px 5px;
        }

        &:last-child {
            border-radius: 0px 5px 5px 0px;

            >span {
                border: none;
            }
        }
    }

    input {

        border-radius: 10px;
        width: 60px !important;
        height: 30px;
        line-height: 25px;
        border: none;
        font-size: .9em;
        padding: 5px;
        color: var(--color-text);
        font-weight: 500;
        outline: none;
        box-sizing: border-box;
        text-align: right;
        background-color: #fff;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        box-shadow: 0 0 0 30px #fff inset !important;
    }
}