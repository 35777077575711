.my-activity-page-container {
    .page-body {
        .my-activity-list-container {
            .item-main-content {
                >b {
                    color: var(--color-primary);
                    margin-bottom: 3px;
                }

                >div {
                    margin-bottom: 10px;

                    >span {
                        margin-right: 3px;
                    }
                }

                >span {
                    font-size: .9em;
                }
            }
        }
    }
}