.checkbox-field-container {
    background-color: var(--color-light);
    border-radius: 10px;
    padding-top: 0px;
    position: relative;


    .field-header {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        //position: absolute;
        width: 100%;
        padding: 3px;
        position: relative;
        box-sizing: border-box;

        >div {
            // position: absolute;
            // right: 4px;
            // top: 4px;
            color: var(--color-text-tint);
            display: flex;
            justify-self: flex-end;
            align-items: center;

            a {

                width: 44px;
                height: 44px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
        }
    }

    .field-body {
        padding: 5px 10px 10px 10px;

        .checkbox {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            padding: 0px 10px;
            border-radius: 10px;
            margin-top: 5px;

            >div:nth-of-type(1) {
                display: inline-flex;
                flex-direction: column;
                align-items: flex-end;

                >span {
                    line-height: 1.1;
                    font-size: 1em;
                    font-weight: 700;
                }

                >p {
                    margin: 5px 0 0 0;
                    padding: 0;
                    font-weight: 300;
                    font-size: .8em;
                    font-style: italic;
                    color: var(--color-medium);
                    text-align: right;
                }
            }

            >div:nth-of-type(2) {
                display: inline-flex;
                margin-left: 20px;
                justify-content: center;
                align-items: center;
                color: var(--color-text);

                >svg {
                    font-size: 35px;
                }

            }
        }

        a.checkbox {
            cursor: pointer;

            >div:nth-of-type(2) {

                color: var(--color-primary);



            }
        }

        .readonly-content {}
    }
}