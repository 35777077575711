.file-list-container {
    .file-list-wrapper {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: flex-start;
    }

    .loading-button-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}