.image-viewer-modal-container {
    .modal-body {
        display: flex;
        justify-content: center;
        padding: 20px;
        height: 100%;

        .image-wrapper {
            width: 100%;
            height: 100%;

            .image-zoom {
                width: 100%;
                height: 100%;
                position: relative;
                //padding: 10px;
                box-sizing: border-box;
                overflow: hidden;
                //border-radius: 10px;
                border: 30px solid var(--color-light);
                //background-color: #fff;
                //margin: 10px;

                img {
                    max-height: 100%;
                    max-width: 100%;
                    height: auto;
                    width: auto;
                    display: block;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    box-sizing: border-box;
                    margin: auto;
                }
            }
        }

    }

}

@media screen and (max-width: 600px) {
    .image-viewer-modal-container {}

}