.icon-picker-modal-container {
    .header-searchbar-container {
        margin-right: 10px;
    }

    .modal-body {
        >div {
            width: 100%;
            max-width: 600px;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;

            >a {
                display: flex !important;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                padding: 10px;
                background-color: #fff;
                margin: 10px;
                border-radius: 10px;
                width: 80px;
                height: 80px;
                cursor: pointer;
                filter: drop-shadow(0px 1.5px 0 #ccc);

                >svg {
                    font-size: 45px;
                }

                >span {
                    margin-top: 4px;
                    text-align: center;
                    font-weight: 500;
                    font-size: .8em;
                }
            }
        }

    }
}