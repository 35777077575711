.address-picker-field-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    >div:nth-of-type(1) {
        flex: 1;

        .readonly-content {
            >div {
                border-color: var(--color-medium);
                border-width: 2px;
                border-radius: 10px;
                border-style: solid;
                box-sizing: border-box;
                position: relative;
                height: 44px;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                >label {
                    position: absolute;
                    top: -13px;
                    left: 10px;
                    display: inline-block;
                    padding: 0 5px;
                    background: #fff;
                    font-weight: 600;
                    color: var(--color-text);

                }

                >span {
                    padding: 0px 15px;
                    font-weight: 400;
                }
            }

        }

        .editable-content {
            display: flex;
            justify-content: center;
            align-items: center;


            .autocomplete-wrapper {
                flex: 1;
                position: relative;

                >label {
                    position: absolute;
                    top: -13px;
                    left: 10px;
                    display: inline-block;
                    padding: 0 5px;
                    background: #fff;
                    font-weight: 600;
                    color: var(--color-text);
                    z-index: 1;

                }

                .MuiTextField-root {
                    fieldset {
                        border-radius: 10px;
                        border-width: 2px;
                        border-style: solid;
                        border-color: var(--color-medium);
                    }
                }

                .MuiAutocomplete-input {
                    height: 28px;
                    font-size: .9em;
                }
            }

            >button {
                margin-left: 5px;

            }
        }
    }


    >div:nth-of-type(2) {
        display: flex;
        justify-content: center;
        align-items: center;

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;

            cursor: pointer;
            color: var(--color-text-tint);
        }
    }




}