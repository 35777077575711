.task-detail-drawer-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 85%;
    max-width: 500px;
    background-color: var(--color-light);
    transition: transform .4s ease-in-out;
    //padding: 10px 10px 0 10px;
    z-index: 1;
    box-sizing: border-box;


    >a {
        display: flex;
        width: 40px;
        height: 40px;
        color: #fff;
        justify-content: center;
        align-items: center;
        border-radius: 10px 0px 0px 10px;
        cursor: pointer;
        position: absolute;
        left: -40px
    }

    >a:nth-of-type(1) {
        top: 40px;
        background-color: var(--color-dark);
    }

    >a:nth-of-type(2) {
        top: 100px;
        background-color: var(--color-primary);

    }

    >a.disabled {
        opacity: .4;
    }

    .task-detail-drawer-body {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow-y: auto;
        padding: 0px 10px 10px 10px;
        box-sizing: border-box;

        .paper-container {
            margin-bottom: 0;
        }
    }
}

.task-detail-drawer-container.visible {
    transform: translateX(0);
}

.task-detail-drawer-container.hidden {
    transform: translateX(100%);
}