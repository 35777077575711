.animated-number-container {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-text);

    >span {
        padding: 0 2px;
    }

}