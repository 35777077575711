.radio-field-container {
    position: relative;
    background-color: var(--color-light);
    border-radius: 10px;
    //padding-top: 20px;
    padding-bottom: 0px;

    .field-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        //position: absolute;
        width: 100%;
        padding: 5px 5px 5px 15px;
        box-sizing: border-box;

        >label {
            display: inline-block;
            //padding: 0 5px;
            //background-color: #fff;
            // position: absolute;
            // left: 10px;
            // top: 5px;
            font-weight: 500;
            color: var(--color-text);
            z-index: 1;
            font-size: 1em;
        }

        >div {
            // position: absolute;
            // right: 4px;
            // top: 4px;
            color: var(--color-text-tint);
            display: flex;
            justify-self: flex-end;
            align-items: center;

            a {

                width: 44px;
                height: 44px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
        }
    }

    .field-body {
        padding: 0px 10px 10px 10px;
    }

    .editable-content {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        //padding: 5px;

        >a {
            display: inline-block;
            border-radius: 99px;
            border: 2px solid var(--color-primary);
            padding: 5px 10px;
            //font-weight: 300;
            color: var(--color-primary);
            font-size: .9em;
            margin: 3px;
            cursor: pointer;

            >span {
                position: relative;
                top: -2px;
            }
        }

        >a.actived {
            background-color: var(--color-primary);
            color: #fff;
            font-weight: 500;
        }
    }

    .readonly-content {
        display: flex;
        justify-content: center;
        align-items: center;

        >div {
            background-color: var(--color-primary);
            padding: 5px 10px;
            border-radius: 99px;
            color: #fff;
            margin: 3px;
            font-weight: 600;


            >span {
                line-height: 1;
                position: relative;
                top: -2px;
                font-weight: .9;
            }
        }

    }
}