.ring-binders-expanded-card-container {
    .search-bar-container {
        background-color: var(--color-light);
    }

    .horizontal-binders {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        >* {
            margin: 10px;
        }
    }
}

@media(min-width: 900px) {}