.divider-tabs-container {
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom-width: 3px;
    border-bottom-style: solid;
    border-bottom-color: var(--color-primary);
    height: 36px;
    box-sizing: border-box;

    position: relative;

    .MuiTabs-root {
        min-height: 44px;
    }

    .MuiTabs-scroller {
        height: 42px;

        .MuiTabs-indicator {
            display: none;
        }

        >div {
            padding-left: 15px;
            padding-right: 20px;

            >button,
            >a {
                filter: drop-shadow(2px 0px 0 #ddd);
                background: var(--color-medium);
                border-radius: 15px 15px 0 0;
                min-height: 40px;
                font-weight: 500;
                line-height: 1;
                color: #fff;
                text-shadow: 0px 0px 19px #555;
                position: relative;

                overflow: inherit !important;

                >div {
                    >svg {
                        position: absolute;
                        top: 20px;
                        fill: var(--color-medium);
                    }

                    >svg:nth-of-type(1) {
                        left: -20px;
                    }

                    >svg:nth-of-type(2) {
                        right: -20px;
                    }


                    >div {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-wrap: nowrap;

                        >div {
                            width: 20px;
                            height: 20px;
                            background-color: rgba($color: #000000, $alpha: .3);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 99px;
                            margin-left: 6px;

                            svg {
                                fill: #fff !important;
                                font-size: 11px;
                                position: relative;
                                left: 0px;
                                top: 0px;
                            }
                        }

                    }
                }
            }



            .Mui-selected {
                background-color: var(--color-primary);

                svg {
                    fill: var(--color-primary);
                }
            }
        }

    }

    .add-btn {
        background-color: var(--color-primary);

    }
}