.radio-item-field-container {


    .item-field-body {

        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        >a {
            display: inline-block;
            border-radius: 99px;
            border: 2px solid var(--color-primary);
            padding: 3px 5px;
            color: var(--color-primary);
            font-size: .9em;
            margin: 3px;
            cursor: pointer;

            >span {
                position: relative;
                top: -2px;
                font-size: .8em;
            }
        }

        >a.actived {
            background-color: var(--color-primary);
            color: #fff;
            font-weight: 500;
        }
    }


}