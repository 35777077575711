.dynamic-list-container {
    padding-top: 30px;
    display: flex;
    //justify-content: center;
    flex-direction: column;

    .list-actions-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
    }

    .list-wrapper {

        display: flex;
        justify-content: center;

        >div {
            width: 100%;
            max-width: 700px;
            padding: 0 10px;
        }


        .loading-button-wrapper {
            display: flex;
            justify-content: center;
            margin-top: 20px;
        }

        .dynamic-list-item-actions {
            svg {
                color: var(--color-primary);
            }
        }
    }
}