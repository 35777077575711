.basic-indicator-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;


    >div {
        span {
            font-weight: 900;
            font-size: 2.2em;
        }
    }

    >span {
        //font-weight: 300;
        color: var(--color-text-tint);
        font-size: .9em;
        display: block;
        text-align: center;
    }
}

.basic-indicator-container.sm {
    >div {
        span {
            font-size: 1.7em;
        }
    }

    >span {
        font-size: .8em;
    }
}