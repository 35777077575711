.content-viewer-container {
    .content-viewer-item-container {
        >div {
            margin-bottom: 25px;
        }

        .image-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            >img {
                width: 100%;
                max-width: 400px;
            }

            >span {
                display: block;
                margin-top: 10px;
                text-align: center;
                font-weight: 300;

            }

        }

        .code-wrapper {
            padding: 10px;
            border-radius: 10px;
            background-color: var(--color-light);

        }

        .table-wrapper {
            >table {
                width: 100%;
                border-collapse: collapse;

                td {
                    border: 1px solid var(--color-medium);
                    padding: 5px;
                }

            }

        }

        .list-wrapper {
            >div {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 10px;
                //background-color: red;
                font-size: 1em;
                box-sizing: border-box;

                >span {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding-right: 5px;

                    svg {
                        font-size: 30px;

                    }
                }

                >div {
                    position: relative;
                    top: -2px;
                    line-height: 1;
                }
            }
        }
    }
}