.workflow-list-item-container {
    background-color: var(--color-light);
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    margin-bottom: 30px;
    //height: 60px;
    border-radius: 20px 20px 20px 20px;
    color: var(--color-text);

    >div:nth-of-type(1) {
        display: flex;
        justify-content: center;
        align-items: center;
        //margin-left: 10px;

        >svg {
            font-size: 35px;
            color: var(--color-text-tint);
        }

        >div {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            font-size: .9em;
            margin-left: 10px;

            >span {
                font-size: .9em;
            }

            >div {
                font-size: .9em;
            }
        }

    }

    >div:nth-of-type(2) {
        display: flex;
        justify-content: space-between;
        align-items: center;

        //width: 120px;

        >a {
            // display: flex;
            // justify-content: center;
            // align-items: center;
            position: relative;
            display: block;
            width: 80px;
            height: 40px;
            cursor: pointer;

            >img {
                border-radius: 99px;
                width: 35px;
                border-width: 3px;
                border-style: solid;
                border-color: var(--color-light);
                position: absolute;
                top: 0;
            }

            >img:nth-of-type(1) {
                left: 0px;
            }

            >img:nth-of-type(1) {
                left: 20px;
                z-index: 1;
            }

            >div {
                border-radius: 99px;
                background-color: var(--color-dark);
                width: 35px;
                height: 35px;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: .9em;

                position: absolute;
                left: 40px;
                z-index: 2;
                border-width: 3px;
                border-style: solid;
                border-color: var(--color-light);

            }

            >span {
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                background-color: var(--color-dark);
                width: 35px;
                height: 35px;
                border-radius: 99px;
            }
        }
    }

    .no-content {
        padding: 3px !important;
        margin: 0 !important;
        position: relative;
        height: 60px;
        //background-color: red;

        >svg {
            font-size: 50px !important;
        }

        >span {
            position: relative;
            font-weight: 400;
            font-size: .8em;
            top: -15px;
        }
    }
}

@media(max-width: 600px) {
    .workflow-list-item-container {
        flex-direction: column;
        align-items: center;

        >div:nth-of-type(2) {
            flex-direction: column;

            .workflow-stepper-cell-container {
                justify-content: center;
            }
        }
    }
}