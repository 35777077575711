.workflow-entry-list-item-container {
    filter: drop-shadow(0px 1px 0 #ccc);
    background-color: #fff;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;

    >div:nth-of-type(1) {
        display: flex;
        justify-content: center;
        align-items: center;

        >svg {
            font-size: 35px;
            color: var(--color-text-tint);
        }

        >div {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            font-size: .9em;
            margin-left: 0px;
            flex: 1;

            >span {
                font-size: .9em;
                display: block;
                margin-bottom: 5px;
            }

            >div {
                >span {
                    font-weight: 400;
                    display: inline-block;
                    margin-right: 4px;
                }
            }
        }
    }

    >div:nth-of-type(2) {
        display: flex;
        justify-content: space-between;
        align-items: center;

    }

    >span {
        position: absolute;
        top: 4px;
        right: 10px;
        color: var(--color-text-tint);
        font-weight: 500;
        font-size: .8em;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        >svg {
            font-size: 20px;
            margin-right: 3px;
        }
    }
}

@media(max-width: 600px) {
    .workflow-entry-list-item-container {
        padding-top: 20px;
        flex-direction: column;
        align-items: center;

        >div:nth-of-type(1) {
            >div {
                >span {
                    width: 100%;
                    text-align: center;
                }
            }


        }

        >div:nth-of-type(2) {
            flex-direction: column;
            margin-top: 10px;
            // .workflow-stepper-cell-container {
            //     justify-content: center;
            // }
        }
    }
}