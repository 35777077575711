.filters-drawer-container {
    .MuiDrawer-paper {
        //background-color: var(--color-light);
        background-color: #fff;
    }

    box-sizing: border-box;
    position: relative;

    .drawer-header {

        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 50px;
        z-index: 1;
        background-color: #fff;
    }

    .drawer-body {
        position: absolute;
        bottom: 0px;
        left: 0;
        right: 0;
        top: 50px;
        //background-color: var(--color-light);
        background-color: #fff;
        overflow-y: scroll;
        padding: 0 10px 75px 10px;

        .fab-btn {
            position: fixed;
            bottom: 10px;
            right: 10px;
        }

        >div {
            background-color: var(--color-light);
            margin: 10px 0px;
            padding: 10px;
            border-radius: 10px;

        }
    }

    .filter-label {
        display: block;
        font-weight: 600;
        color: var(--color-text-tint);
        margin-bottom: 10px;

    }

    .radio-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;


        >a {
            background-color: #fff;
            border-radius: 99px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            cursor: pointer;
            line-height: 1;
            margin: 5px;
        }

        >a.selected {
            background-color: var(--color-primary);
            color: #fff;
        }

    }

    .value-wrapper {
        margin-top: 10px;

        >input {
            border: 2px solid var(--color-medium);
            box-sizing: border-box;
            border-radius: 10px;
            height: 40px;
            line-height: 35px;
            background-color: #fff;
        }

        >input:nth-of-type(2) {
            margin-top: 10px;
        }
    }

    .autocomplete-wrapper {
        background-color: #fff;
        border-radius: 10px;
    }
}