.tags-cell-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    >span {
        margin: 5px;
        border-style: solid;
        padding: 5px 8px;
        display: flex;
        justify-content: center;
        align-items: center;

        >svg {
            margin-right: 5px;
            font-size: 17px;
        }

        >span {
            line-height: 1;
            font-weight: 500;
            position: relative;
            top: -1px;
            font-size: .9em;
        }
    }
}