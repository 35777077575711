.multi-action-expanded-card-container {
    .expanded-card-body {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        max-width: 600px;
        margin: 0 auto;

        >a {
            margin-bottom: 40px;
        }
    }
}