.website-builder-modal-container {
    .configuration-item-selector-container {
        .items-wrapper {
            display: flex;
            justify-content: 'flex-start';
            align-items: 'flex-start';
            flex-wrap: wrap;

            .card-button-container {
                font-size: .9em;
                height: 55px;
                line-height: 1;
                width: 50px;
                margin: 5px 5px 15px 5px;

                >svg {
                    font-size: 28px;
                }

                >strong {
                    font-size: 11px;
                    top: -4px;
                }
            }
        }
    }

    .configuration-item-selector-container.disabled {
        .items-wrapper {
            .card-button-container {
                pointer-events: none;
                opacity: 0.5;
            }
        }
    }
}