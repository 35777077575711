.multi-line-chart-container {
    padding: 5px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;

    >.chart-wrapper {
        width: 100%;
        max-width: 700px;
        height: 500px;
    }
}