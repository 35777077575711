.step-builder-modal-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    .modal-body {
        padding-bottom: 80px;
    }

    .fab-btn {
        position: fixed;
        bottom: 20px;
        right: 20px;
        z-index: 99999999;
    }
}