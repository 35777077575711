.form-dialog-container {

    .form-dialog-title {
        font-weight: 900;
        text-align: center;
        margin-top: 10px;
        padding: 5px;
        font-size: 1.1em;
    }

    .form-dialog-description {
        font-weight: 300;
        padding-bottom: 20px;
        text-align: center;

    }

    .dynamic-form-container {
        >form {
            >h2 {
                top: -10px !important;
                right: 0;
                width: 100%;
                text-align: center;

            }
        }
    }
}