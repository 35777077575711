.multi-select-filter-container {
    .autocomplete-wrapper {
        flex: 1;
        position: relative;

        .MuiTextField-root {
            fieldset {
                border-radius: 10px;
                border-width: 2px;
                border-style: solid;
                border-color: var(--color-medium);
            }
        }

        .MuiAutocomplete-input {
            //height: 28px;
            font-size: .9em;
        }

        .MuiChip-root {
            >span {
                color: var(--color-text-shade) !important;
                font-weight: 500;
            }

            >svg {
                color: var(--color-text);
            }
        }
    }
}