.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;

    >.header-title {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        flex: 1;

        >h2 {
            font-weight: 900;
            font-size: 1.4em;
            margin: 0px;
            padding: 0px;
        }

        >span {
            font-weight: 300;
            font-size: 1em;
        }
    }

    .search-bar-wrapper {
        margin-right: 10px;
    }

    >.header-start-btns {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    >.header-end-btns {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        >*:not(:last-child) {
            margin-right: 10px;
        }

    }

}

.header-active-filters-wrapper {
    padding: 0px 15px;

    .active-filters-container {
        margin-bottom: 0px;

    }

}