.selectable-content-viewer-container {
    width: 100%;
    //background-color: red;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 10px;
    //overflow: hidden;

    * {
        box-sizing: border-box;
    }
}