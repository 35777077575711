.my-tasks-page-container {
    .page-body {
        padding-bottom: 25px;

        .item-main-content {
            .description-wrapper {
                margin-top: 5px;
            }

            .due-date-wrapper {
                margin-top: 5px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                font-size: .9em;

                >svg {
                    font-size: 20px;
                }
            }
        }
    }
}