.dynamic-table-container {
    .empty-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 100px;

        >svg {
            font-size: 50px;
            color: var(--color-medium);
        }

        >p {
            font-weight: 500;
            color: var(--color-text);
        }

    }

    .spinner-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 100px;
    }

    .loading-button-wrapper {
        display: flex;
        justify-content: center;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .table-wrapper {
        padding: 0 20px;

        table {
            border-collapse: separate;
            border-spacing: 0 10px;
            display: table;
            margin: 0;
            padding: 0;
            width: 100%;

            thead {
                >tr {
                    th {
                        font-weight: 600;
                        font-size: .9em;

                        &:last-child {
                            width: 30px;
                        }

                        a {
                            cursor: pointer;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            line-height: 1;
                            text-decoration: none;
                            color: var(--color-text);
                            padding: 0 5px;

                            svg {
                                font-size: .8em;
                                margin-right: 5px;
                            }
                        }
                    }
                }
            }

            th {
                text-align: left;
                padding: 0 0 0 10px;
                text-transform: uppercase;
            }

            tbody {
                >tr {
                    filter: drop-shadow(0px 1.5px 0 #ccc);
                }
            }

            tr {
                background: transparent;
                border-radius: 10px;
                padding-bottom: 10px;
                width: 100%;

                >td {
                    background: white;
                    padding: 5px;

                    >span {
                        padding: 5px;
                        display: block;
                        border-right: 1px solid #e8e8e8;

                        ion-thumbnail {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        >span.cell-money {
                            display: flex;
                            flex-wrap: nowrap;
                            align-items: center;
                            font-weight: 700;

                            >.bib-currency {
                                margin-right: 5px;
                                display: block;
                            }

                        }
                    }

                    &:first-child {
                        border-radius: 5px 0px 0px 5px;
                    }

                    &:last-child {
                        border-radius: 0px 5px 5px 0px;

                        >span {
                            border: none;
                        }
                    }

                    &:nth-last-child(2) {
                        >span {
                            border: none;
                        }
                    }

                    strong {
                        font-weight: 800;
                    }
                }
            }
        }

        th {
            position: -webkit-sticky;
            position: sticky;
            top: 0px;
            z-index: 2;
            background-color: var(--color-light);
            padding: 10px 5px !important;
            // height: 50px;
        }
    }


}





@media screen and (max-width: 600px) {
    .dynamic-table-container {
        .table-wrapper {
            table {
                thead {
                    border: none;
                    clip: rect(0 0 0 0);
                    height: 1px;
                    margin: -1px;
                    overflow: hidden;
                    padding: 0;
                    position: absolute;
                    width: 1px;
                }

                tr {
                    background: white !important;
                    //box-shadow: 0 .5px 0 0 #ccc;
                    filter: drop-shadow(0px 2px 0 #ccc) !important;
                    display: block;
                    margin-bottom: 25px;
                    padding: 10px;
                    position: relative;
                    box-sizing: border-box;

                    td {
                        align-items: center;
                        background-color: transparent !important;
                        border-bottom: none;
                        display: flex;
                        justify-content: space-between;
                        text-align: right;

                        &:before {
                            content: attr(data-column-header);
                            font-weight: 600;
                            text-transform: uppercase;
                            text-align: start;
                        }

                        >span {
                            border: none !important;
                            margin-bottom: 0 !important;
                            padding: 0 !important;
                            text-align: right;
                        }

                        // &:last-child {
                        //     display: none;
                        // }
                        &:last-child {
                            //position: relative;
                            display: flex;
                            justify-content: center;
                            height: 5px;

                            >button {
                                position: relative;
                                bottom: -15px;
                                background-color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }

}