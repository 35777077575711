.simple-indicator-card-body-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 125px;

    .current-data {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        >strong {
            font-size: 15px;
            font-weight: 900;
        }

        >div {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            margin-top: 2px;

            >span {
                display: block;
                margin-left: 3px;
                font-weight: 400;
                position: relative;
                //top: -1px;
                font-size: 12px;
            }
        }
    }
}