.thumbnail-cell-container {
    display: flex;
    justify-content: center;

    img {
        width: 50px;
        border-radius: 10px;
        margin: 0;
        display: block;
    }
}