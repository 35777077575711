.breakdown-chart-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;

    .graph-wrapper {
        padding: 5px;
        width: 100%;

        .graph {
            background-color: #fff;
            display: flex;
            flex-direction: row;
            box-sizing: border-box;
            align-items: center;
            justify-content: center;


            >span {
                //box-sizing: border-box;
                height: 100%;
                background-color: var(--color-text);
                margin-left: .5%;
                animation: animWidth .5s ease-in-out;


                &:first-child {
                    border-radius: 5px 0 0 5px;
                }

                &:last-child {
                    border-radius: 0 5px 5px 0;
                }
            }
        }
    }

    .legend {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        >a.legend-item {
            padding: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: .9em;
            cursor: pointer;

            >span.legend-color {
                width: 20px;
                height: 20px;
                margin-right: 10px;

            }

            >span.legend-label {
                font-weight: 400;
                font-size: .9em;
                color: var(--color-text);
                flex: 1;
            }
        }
    }

    @keyframes animWidth {
        from {
            width: 0%;
        }
    }
}

.breakdown-item-tooltip {
    color: var(--color-text-shade);
    overflow-y: auto;
    max-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    >strong {
        font-weight: 800;
        color: var(--color-text);
        margin-bottom: 3px;
    }

    >span {
        font-weight: 300;
        font-size: 1em;
        margin-bottom: 3px;
    }

    >div {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        >strong {
            display: inline-block;
            font-weight: 900;
            font-size: 1.2em;
            margin-right: 3px;
        }

        >span {
            color: var(--color-text-tint);
        }

    }
}