.field-edit-controls-container {
    display: flex;
    justify-content: center;
    align-items: center;
    //flex-wrap: nowrap;

    >* {
        margin: 0 4px;
    }

    .spinner-wrapper {
        width: 35px !important;
        height: 35px;
        //background-color: red;
        margin-top: 0 !important;

    }
}