.money-cell-container {
    >span {
        display: block;
        text-align: right;
        color: var(--color-text-tint);
        font-weight: 400;
        font-size: .9em;
    }

    >div {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        >span {
            display: block;
            margin-right: 5px;
            font-weight: 700;
        }
    }
}