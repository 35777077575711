.periodicity-field-container {
    position: relative;
    background-color: var(--color-light);
    border-radius: 10px;
    padding: 10px;

    >label {
        display: inline-block;
        padding: 0 5px;


        font-weight: 500;
        color: var(--color-text);
        z-index: 1;
        font-size: 1em;
    }

    >div {
        margin-top: 10px;
    }

    .options {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        padding: 5px;

        >a {
            display: inline-block;
            border-radius: 99px;
            border: 2px solid var(--color-primary);
            padding: 5px 10px;
            //font-weight: 300;
            color: var(--color-primary);
            font-size: .9em;
            margin: 3px;
            cursor: pointer;
        }

        >a.actived {
            background-color: var(--color-primary);
            color: #fff;
            font-weight: 500;
        }
    }
}