.icon-maker-field-container {
    position: relative;
    background-color: var(--color-light);
    border-radius: 10px;
    //padding-top: 20px;
    padding-bottom: 0px;

    .field-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 10px 0px 15px;

        >label {
            display: inline-block;
            font-weight: 500;
            color: var(--color-text);
            font-size: 1em;
            padding-top: 10px;
        }
    }

    .field-body {
        padding: 5px 10px 10px 10px;

        .icon-preview {
            display: flex;
            justify-content: center;
            align-items: center;

            >span {
                display: block;
                width: 100px;
                height: 100px;
                border-width: 1px;
                border-radius: 10px;
                border-style: solid;
                border-color: var(--color-medium);
            }

        }

        .add-path-btn-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .path-items {
            padding-right: 20px;

            .path-item {
                background-color: #fff;
                padding: 10px;
                border-radius: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;
                padding-right: 25px;
                margin-bottom: 15px;

                >div:nth-of-type(2) {
                    flex: 1;
                    padding-left: 10px;
                    padding-right: 3px;
                }

                >div:nth-of-type(3) {
                    width: 44px;
                    height: 44px;
                    border-radius: 99px;
                    background-color: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    right: -20px;
                }

            }
        }

        .readonly-content {
            display: flex;
            justify-content: center;
        }
    }

}