.configuration-importer-modal-container {
    position: relative;

    .upload-input {
        display: none;
    }

    .upload-button-wrapper {
        display: flex;
        justify-content: center;
        margin-top: 40px;

        .upload-button {
            //margin-left: 10px;

            strong {
                position: relative;
                top: 2px;
            }
        }

    }

    .fab-btn {
        position: fixed;
        bottom: 20px;
        right: 20px;
    }


}

@media screen and (max-width: 600px) {}