.fix-unfix-cards-modal-container {
    .modal-window {
        width: 100%;
        height: 80%;
        max-width: 800px;
        padding: 10px;
        background-color: #fff;
        border-radius: 20px;
        position: relative;
        filter: drop-shadow(0px 2px 0 #bbb);

        >a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            border-radius: 99px;
            background-color: #fff;
            position: absolute;
            right: -20px;
            top: -20px;
            color: var(--color-text);
            cursor: pointer;

            >svg {
                font-size: 20px;
            }
        }

        >div {
            overflow: auto;
            height: 100%;

            .header-searchbar-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 70px;
            }

            .cards-wrapper {
                display: flex;
                justify-content: center;
                align-items: flex-start;
                flex-wrap: wrap;
                margin-top: 10px;

                >a {
                    background-color: var(--color-light);
                    padding: 10px;
                    border-radius: 10px;
                    width: 110px;
                    height: 110px;
                    margin: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    cursor: pointer;
                    color: var(--color-text-tint);
                    text-align: center;
                    font-weight: 700;
                    box-sizing: border-box;
                    font-size: .9em;

                    >span {
                        position: absolute;
                        top: -4px;
                        right: -4px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 23px;
                        height: 23px;
                        transition: transform .5s;

                        >svg:nth-of-type(1) {
                            position: absolute;
                            top: -1px;
                            left: -1px;
                            color: var(--color-light);
                            font-size: 28px;
                        }

                        >svg:nth-of-type(2) {
                            position: absolute;
                            top: 1px;
                            left: 1px;
                            font-size: 24px;
                        }
                    }
                }

                >a.fixed {
                    >span {
                        transform: rotate(-35deg);

                        svg:nth-of-type(2) {
                            color: var(--color-primary);
                        }
                    }
                }
            }

            .loading-button-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .spinner-wrapper {
        margin-top: 200px;
    }
}