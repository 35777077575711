.board-card-container {

    width: 100%;
    height: 100%;

    >div {
        height: 195px;
        background-color: #fff;
        border-radius: 20px;
        padding: 10px;

        position: relative;
        box-sizing: border-box;
        filter: drop-shadow(0px 2px 0 #ccc);

        .card-title {
            font-weight: 600;
            font-size: .9em;
            margin: 0;
            padding: 0 0 5px 0;
            text-align: center;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            line-clamp: 2;

            height: 2.4em;

        }

        >.card-btns {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            left: 0;
            right: 0;
            bottom: -15px;
            z-index: -1;

            >button,
            div {
                background-color: #fff;
                width: 40px;
                height: 40px;
                border-radius: 99px;
                margin-left: 5px;
                margin-right: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: none;

                svg {
                    color: var(--color-text);
                    font-size: 20px;
                    position: relative;
                    bottom: -3px;
                }
            }

        }
    }


}