.mini-column-chart-container {
    align-items: flex-end;
    display: flex;
    flex: 1;
    flex-direction: row;

    >a {
        display: block;
        animation: animHeight .5s ease-in-out;
        flex: 1;
        margin-right: 5px;
        border-radius: 99px;
        background-color: var(--color-medium);
        cursor: pointer;

        &:last-child {
            margin-right: 0px;
        }
    }
}

@keyframes animHeight {
    from {
        height: 0px;
    }
}