.address-form-modal-container {
    .header-container {
        position: relative;
        z-index: 1;

        svg {
            color: var(--color-primary);
        }
    }

    .modal-body {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        .map-container {
            .places-autocomplete-wrapper {
                position: absolute;
                top: 0px;
                left: 90px;
                right: 90px;
                background-color: rgba($color: #fff, $alpha: .8);
                z-index: 1;
                padding: 5px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                >.info-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;



                    >div {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        width: 100%;
                        max-width: 600px;

                        >svg {
                            margin: 5px;
                            font-size: 20px;
                        }

                        >span {
                            font-weight: 300;
                        }
                    }
                }

                >.input-wrapper {
                    width: 100%;
                    max-width: 450px;
                    margin-top: 5px;

                    >div {
                        width: 100%;
                        max-width: 450px;
                        box-sizing: border-box;
                        border-color: var(--color-medium);
                        border-width: 2px;
                        border-radius: 10px;
                        border-style: solid;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;

                        >svg {
                            margin-right: 2px;
                            margin-left: 5px;
                        }

                        >div {
                            flex: 1;


                            .combobox-input {
                                width: 100%;
                                // padding-top: 10px;
                                // padding-bottom: 10px;
                                box-sizing: border-box;
                                height: auto;
                            }
                        }

                    }
                }
            }
        }
    }

    .form-wrapper {
        position: absolute;
        bottom: 0px;
        left: 90px;
        right: 90px;
        background-color: rgba($color: #fff, $alpha: .8);
        z-index: 1;
        padding: 20px 5px 5px 5px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;

        >div {
            width: 33%;
            padding: 5px;
            margin-top: 10px;
        }

        .input-field-container {
            //background-color: #e2e2e2;
            background-color: #fff;

            >label {
                //background-color: #e2e2e2;
                background-color: #fff;
            }

        }
    }
}

@media(max-width: 600px) {
    .address-form-modal-container {


        .modal-body {


            .map-container {
                .places-autocomplete-wrapper {

                    top: 10px;
                    left: 10px;
                    right: 10px;

                }
            }
        }

        .form-wrapper {

            bottom: 10px;
            left: 10px;
            right: 10px;

            >div {
                width: 47%;
            }
        }
    }
}