.indicator-expanded-card-container {
    .subtitle-wrapper {
        padding: 0px 7px 10px 7px;
    }

    .pill-tabs {
        .MuiTabs-scroller {
            >div {

                button,
                a {
                    //filter: none !important;
                    //background: var(--color-light);
                }
            }
        }
    }

    .expanded-card-body {
        .current-period-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .export-btns {
        display: flex;
        justify-content: center;
        align-items: center;

        >a {
            margin: 20px;
        }

    }

    .export-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        max-width: 600px;
        margin: 0 auto;

        >a {
            margin-bottom: 40px;
        }
    }

    .sheet-wrapper {
        padding: 10px;
        border-radius: 10px;
        background-color: var(--color-light);

        >.paper {
            margin-bottom: 0px;
        }

    }
}